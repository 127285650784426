import { ApolloQueryResult } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useGetSelectFinancialAdvisorModalContent } from '../contentstack';

import { useFinancialAdvisorParties } from './useFinancialAdvisorParties';

import { PartyType, PersonType } from '~/__generated__';
import { DropdownItem } from '~/components';
import { GetFinancialAdvisorParties } from '~/hooks/party/symphony/__generated__/GetFinancialAdvisorParties';
import { AsyncResult, ContentOptions } from '~/utils';

interface SelectFinancialAdvisorContentData {
  content: {
    cta: {
      primary: string;
      secondary: string;
    };
    dropdown: {
      emptyResults: string;
      label: string;
      placeholder: string;
    };
    message: string;
    subMessage: string;
    title: string;
  };
  financialAdvisors: DropdownItem<string>[];
  refetchFinancialAdvisors?: (partyName: string | null) => Promise<ApolloQueryResult<GetFinancialAdvisorParties>>;
}

interface SelectFinancialAdvisorContentVariables {
  assistantPartyId?: string;
  contentOptions: ContentOptions;
}

export const useSelectFinancialAdvisorContent = ({
  contentOptions,
  assistantPartyId,
}: SelectFinancialAdvisorContentVariables): AsyncResult<SelectFinancialAdvisorContentData> => {
  const [state, setState] = useState<AsyncResult<SelectFinancialAdvisorContentData>>({
    loading: true,
  });

  const {
    data: contentstackData,
    error: contentstackError,
    loading: contentstackLoading,
  } = useGetSelectFinancialAdvisorModalContent({ variables: contentOptions });

  const {
    data: financialAdvisorPartiesData,
    loading: financialAdvisorPartiesLoading,
    error: financialAdvisorPartiesError,
  } = useFinancialAdvisorParties({
    expanded: true,
    personType: PersonType.EMPLOYEE,
    partyType: PartyType.PERSON,
    assistantPartyId,
  });

  useEffect(() => {
    if (contentstackLoading || financialAdvisorPartiesLoading) {
      return;
    }

    if (contentstackError || financialAdvisorPartiesError) {
      setState({ error: contentstackError || financialAdvisorPartiesError, loading: false });
      return;
    }

    if (contentstackData) {
      const modalContent = contentstackData.all_select_financial_advisor_modal?.items?.[0]?.modal;
      setState({
        data: {
          content: {
            cta: {
              primary: modalContent?.cta?.primary ?? '',
              secondary: modalContent?.cta?.secondary ?? '',
            },
            dropdown: {
              emptyResults: modalContent?.dropdown?.empty_results ?? '',
              label: modalContent?.dropdown?.label ?? '',
              placeholder: modalContent?.dropdown?.placeholder ?? '',
            },
            message: modalContent?.message ?? '',
            subMessage: modalContent?.sub_message ?? '',
            title: modalContent?.title ?? '',
          },
          financialAdvisors: financialAdvisorPartiesData?.parties ?? [],
          refetchFinancialAdvisors: financialAdvisorPartiesData?.refetchParties,
        },
        loading: false,
      });
    }
  }, [
    contentstackData,
    contentstackError,
    contentstackLoading,
    financialAdvisorPartiesData?.parties,
    financialAdvisorPartiesError,
    financialAdvisorPartiesLoading,
  ]);

  return state;
};
