import {
  GetSelectFinancialAdvisorModalContent,
  GetSelectFinancialAdvisorModalContentVariables,
} from './__generated__/GetSelectFinancialAdvisorModalContent';
import * as queries from './query.gql';

import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useContentstackQuery } from '~/utils/contentstack';

export const useGetSelectFinancialAdvisorModalContent = (
  options?: QueryHookOptions<GetSelectFinancialAdvisorModalContent, GetSelectFinancialAdvisorModalContentVariables>,
): QueryResult<GetSelectFinancialAdvisorModalContent, GetSelectFinancialAdvisorModalContentVariables> => {
  return useContentstackQuery(queries.GetSelectFinancialAdvisorModalContent, options);
};
