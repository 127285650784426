import { DropdownItem, SxProps, Theme } from '../ui';

import { HoldingsData } from './types';

export const getDefaultMarketValue = (item: {
  marketValue?: string;
  unitPrice?: string;
  units?: string | null;
}): string => {
  if (!item.units) {
    return '';
  } else if (item.unitPrice) {
    const unitPrice = parseFloat(item.unitPrice);
    const units = parseFloat(item.units);
    return (unitPrice * units).toFixed(2);
  }
  return parseFloat(item.marketValue?.replace(/[,$]/g, '') || '0').toFixed(2);
};

export const disabledFieldStyles: SxProps<Theme> = { background: '#eee', pointerEvents: 'none' };

export const getDisabledInputStyles = (
  isManualSecurity: boolean,
  isInputFieldDisabled?: boolean,
): SxProps<Theme> | undefined => (isInputFieldDisabled || isManualSecurity === false ? disabledFieldStyles : undefined);

export const getQuantityInputStyles = (
  isConnectedAccountDetailsModal: boolean,
  security: { identifier?: string | null; isManualSecurity: boolean },
): SxProps<Theme> | undefined =>
  isConnectedAccountDetailsModal || (security.isManualSecurity === false && !security.identifier)
    ? disabledFieldStyles
    : undefined;

export const SIMPLE_SECURITY_TYPE_CASH = 'CASH';

export const getAssetCategoryOptions = (
  assetCategoryContent: DropdownItem[],
  assetCategoryCash: string,
  simpleSecurityType?: string | null,
): DropdownItem[] => {
  const cashAndEquivalentItem = assetCategoryContent.filter(ac => ac.value === assetCategoryCash);
  return simpleSecurityType === SIMPLE_SECURITY_TYPE_CASH ? cashAndEquivalentItem : assetCategoryContent;
};

export const getExpenseRatioOptions = (
  expenseRatioContent: DropdownItem[],
  isManualSecurity: boolean,
  expenseRatio?: string | null,
  disableExpenseRatio?: boolean,
): DropdownItem[] => {
  return isManualSecurity === false || expenseRatio || disableExpenseRatio
    ? expenseRatio
      ? [
          {
            label: `${expenseRatio}%`,
            value: expenseRatio,
          },
        ]
      : expenseRatioContent
    : expenseRatioContent;
};

export const getHoldingName = (ticker?: string | null, securityName?: string | null) =>
  ticker ? `${ticker} ${securityName ? `- ${securityName}` : ''}` : securityName ? securityName : '';

export const getIsDuplicateHolding = (holdingName?: string | null, holdings?: HoldingsData[]) => {
  return (holdings?.filter(h => h.security.identifier?.name === holdingName)?.length ?? 0) > 1;
};
