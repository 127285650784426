import debounce from 'lodash/debounce';
import React, { SyntheticEvent, useMemo, useState } from 'react';

import { useSelectFinancialAdvisorContent } from './hooks/useSelectFinancialAdvisorContent';

import {
  Autocomplete,
  Box,
  DropdownItem,
  Grid,
  LoadingButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from '~/components';
import { ContentOptions } from '~/utils';

export interface SelectFinancialAdvisorModalProps {
  assistantPartyId?: string;
  contentOptions: ContentOptions;
  dataQa?: string;
  onSelectFA: (partyId: string) => void;
}

export const SelectFinancialAdvisorModal: React.FC<SelectFinancialAdvisorModalProps> = ({
  contentOptions,
  dataQa = 'select-financial-advisor-modal',
  onSelectFA,
  assistantPartyId,
}) => {
  const {
    sfAutocomplete: { styles: sfAutocompleteStyles },
  } = useTheme();
  const [partyId, setPartyId] = useState('');

  const {
    data: selectFinancialAdvisorData,
    error: selectFinancialAdvisorError,
    loading: selectFinancialAdvisorLoading,
  } = useSelectFinancialAdvisorContent({ contentOptions, assistantPartyId });

  const content = selectFinancialAdvisorData?.content;
  const financialAdvisors = selectFinancialAdvisorData?.financialAdvisors ?? [];

  const error = useMemo(() => selectFinancialAdvisorError, [selectFinancialAdvisorError]);
  const loading = useMemo(() => selectFinancialAdvisorLoading, [selectFinancialAdvisorLoading]);

  const handleSelection = () => {
    onSelectFA(partyId);
  };

  const onFinancialAdvisorsInputChange = (event: SyntheticEvent | null, value: string | null) => {
    // Only refetch financial advisors if there's a user input or if field is cleared
    if (event?.type === 'change' || (event?.type && !value && ['click', 'blur'].includes(event.type))) {
      setPartyId('');
      selectFinancialAdvisorData?.refetchFinancialAdvisors?.(value);
    }
  };

  const debounceSearchInput = debounce(onFinancialAdvisorsInputChange, 300);

  return (
    <Modal
      actions={
        <LoadingButton disabled={!partyId} onClick={handleSelection} variant="contained">
          {content?.cta.primary}
        </LoadingButton>
      }
      content={
        <Grid container direction="column">
          <Grid item>
            <Typography sx={{ lineHeight: 1.5 }} variant="h4" variantMapping={{ h4: 'p' }}>
              {content?.message}
            </Typography>
          </Grid>
          {content?.subMessage && (
            <Grid item sx={{ mt: 3 }}>
              <Typography variant="body2">{content.subMessage}</Typography>
            </Grid>
          )}
          <Grid item sx={{ mt: 3 }}>
            <Autocomplete
              filterOptions={options => options}
              noOptionsText={content?.dropdown.emptyResults ?? ''}
              onChange={(_e, value) => {
                setPartyId(value ? value.value.toString() : '');
              }}
              onInputChange={debounceSearchInput}
              options={financialAdvisors}
              renderInput={params => (
                <TextField
                  data-qa={`${dataQa}-ticker-search`}
                  placeholder={content?.dropdown.placeholder}
                  sx={{
                    '& .MuiAutocomplete-inputRoot': {
                      borderRadius: sfAutocompleteStyles.borderRadius,
                    },
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              renderOption={(props, option: DropdownItem<string>) => (
                <Box component="li" {...props} data-qa={`${dataQa}-advisor-${option.value}`} key={option.value}>
                  {option.label}
                </Box>
              )}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      }
      contentOptions={contentOptions}
      dataQa={dataQa}
      error={error}
      loading={loading}
      open
      title={content?.title}
    />
  );
};
