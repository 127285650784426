import { ApolloQueryResult } from '@apollo/client';
import { useEffect, useState } from 'react';

import { PartyType, PersonType } from '~/__generated__';
import { DropdownItem } from '~/components';
import { useGetFinancialAdvisorParties } from '~/hooks/party/symphony';
import {
  GetFinancialAdvisorParties,
  GetFinancialAdvisorPartiesVariables,
} from '~/hooks/party/symphony/__generated__/GetFinancialAdvisorParties';
import { getFullName } from '~/hooks/party/utils';
import { AsyncResult } from '~/utils';

interface FinancialAdvisorPartiesVariables {
  assistantPartyId?: string;
  expanded?: boolean;
  partyType: PartyType;
  personType: PersonType;
}

interface FinancialAdvisorPartiesData {
  parties: DropdownItem<string>[];
  refetchParties: (partyName: string | null) => Promise<ApolloQueryResult<GetFinancialAdvisorParties>>;
}

/**
 * Hook to get the party DropDownItem array containing the name as the label and partyId as the value.
 * @param arg - The FinancialAdvisorPartiesVariables object.
 * @param [arg.expanded=false] - False will return the party base properties only.
 * @param arg.partyType - The party type.
 * @param arg.personType - The person type.
 * @returns Async result where data is a DropdownItem array
 */
export function useFinancialAdvisorParties({
  expanded,
  partyType,
  personType,
  assistantPartyId,
}: FinancialAdvisorPartiesVariables): AsyncResult<FinancialAdvisorPartiesData> {
  const [state, setState] = useState<AsyncResult<FinancialAdvisorPartiesData>>({ loading: true });

  // Page size is currently set to 20, and cursor is undefined because this is used in an autocomplete instead of a paginated table
  const getVariables = (partyName: string | null, partyId?: string | null): GetFinancialAdvisorPartiesVariables => {
    return {
      expanded,
      partyType,
      personType,
      partyName: partyName ? partyName : null,
      pagination: { pageSize: 20 },
      partyId,
    };
  };

  const { data, loading, error, refetch } = useGetFinancialAdvisorParties({
    variables: getVariables(null, assistantPartyId),
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    if (error) {
      setState({ loading: false, error });
      return;
    }

    setState({
      loading: false,
      data: {
        parties:
          data?.getParties?.reduce((acc, curr): DropdownItem<string>[] => {
            const name = getFullName(curr.partyPerson) ?? '';
            const partyId = curr.id ?? '';

            if (partyId !== assistantPartyId && name && partyId) {
              const dropdownItem: DropdownItem<string> = { label: name, value: partyId };
              acc.push(dropdownItem);
            }
            return acc;
          }, [] as DropdownItem<string>[]) ?? [],
        refetchParties: partyName => refetch(getVariables(partyName, assistantPartyId)),
      },
    });
  }, [data?.getParties, error, loading]);

  return state;
}
