import { QueryHookOptions, QueryResult } from '@apollo/client';

import {
  GetFinancialAdvisorParties,
  GetFinancialAdvisorParties_getParties_partyPerson,
  GetFinancialAdvisorPartiesVariables,
} from './__generated__/GetFinancialAdvisorParties';
import * as getFinancialAdvisorPartiesQuery from './GetFinancialAdvisorParties.gql';

import { useSymphonyQuery } from '~/utils';

export type PartyPerson = GetFinancialAdvisorParties_getParties_partyPerson;

export const useGetFinancialAdvisorParties = (
  options?: QueryHookOptions<GetFinancialAdvisorParties, GetFinancialAdvisorPartiesVariables>,
): QueryResult<GetFinancialAdvisorParties, GetFinancialAdvisorPartiesVariables> =>
  useSymphonyQuery(getFinancialAdvisorPartiesQuery.GetFinancialAdvisorParties, options);
